import React from "react";
import { FaJava, FaJs, FaNodeJs, FaReact, FaEthereum, FaDatabase } from "react-icons/fa";
import { SiGo, SiSolidity, SiTypescript } from "react-icons/si";
import "../styles/Languages.css";

const Languages = () => {
  const languages = [
    { name: "Solidity", icon: <SiSolidity /> },
    { name: "Java", icon: <FaJava /> },
    { name: "Golang", icon: <SiGo /> },
    { name: "JavaScript", icon: <FaJs /> },
    { name: "Node.js", icon: <FaNodeJs /> },
    { name: "React", icon: <FaReact /> },
    { name: "Ethereum Smart Contracts", icon: <FaEthereum /> },
    { name: "Blockchain Databases", icon: <FaDatabase /> }
  ];

  return (
    <div className="languages-container">
      <h1 className="languages-title">Supported Blockchain Languages</h1>
      <p className="languages-subtitle">Building secure and scalable blockchain applications</p>
      <div className="languages-grid">
        {languages.map((lang, index) => (
          <div className="language-card" key={index}>
            <div className="icon">{lang.icon}</div>
            <p>{lang.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Languages;
